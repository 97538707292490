<template>
  <el-dialog
    title="添加扩展项"
    width="40%"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <span class="moreTag" v-for="(item, index) in dynamicTags" :key="index">
      <el-tag
        v-if="!item.inputShow"
        :disable-transitions="false"
        @click="handleEdit(item, index)"
      >
        {{ item.title }}
        <i
          v-if="item.flag === true"
          @click.stop="handleDelete(index)"
          class="el-icon-close"
        ></i>
        <i v-else class="el-icon-edit"></i>
      </el-tag>
      <el-input
        v-else
        class="input-new-tag"
        v-model="item.title"
        size="small"
        focus
        @keyup.enter.native="handleTagConfirm(item, index)"
      >
      </el-input>
    </span>
    <el-input
      focus
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm"
    >
    </el-input>
    <el-button
      v-if="!inputVisible && dynamicTags.length < 20"
      class="button-new-tag"
      size="small"
      @click="showInput"
      >+ 扩展项名称</el-button
    >
    <div
      slot="footer"
      class="dialog-footer"
      style="border-top: 1px solid #ccc; padding-top: 8px"
    >
      <span style="float: left; margin-top: 10px; color: #67c23a">
        <i class="el-icon-info"> </i> 回车保存录入项</span
      >
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="confirm"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      dialogVisible: false,
      CompanyId: "",
      loading: false,
      jsonId: "",
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
    },
    confirm() {
      this.handleInputConfirm();
      if (this.dynamicTags.length <= 0)
        return this.$message.error("请至少添加一项");
      let arr = this.dynamicTags.map((item) => {
        return {
          dataIndex: item.dataIndex,
          title: item.title,
        };
      });
      this.loading = true;
      this.$http
        .post("/Base_Manage/Base_Attribute/SaveData", {
          Id: this.jsonId,
          CompanyId: this.CompanyId,
          Content: JSON.stringify(arr),
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.dialogVisible = false;
            this.$emit("close", true);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 编辑
    handleEdit(item, index) {
      if (!item.inputShow) {
        item.inputShow = true;
      } else {
        item.inputShow = !item.inputShow;
      }
      this.$set(this.dynamicTags, index, item);
    },
    // tag编辑
    handleTagConfirm(item, index) {
      item.inputShow = !item.inputShow;
      this.$set(this.dynamicTags, index, item);
    },
    // tag删除
    handleDelete(index) {
      this.dynamicTags.splice(index, 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push({
          inputShow: false,
          dataIndex: "Symbol" + (this.dynamicTags.length + 1),
          title: inputValue,
          flag: true,
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    openDialog(CompanyId) {
      this.CompanyId = CompanyId;
      this.dialogVisible = true;
      this.$http
        .post(
          "/Base_Manage/Base_Attribute/GetInventSymbolList?CompanyId=" +
            CompanyId
        )
        .then((res) => {
          if (res.Success && res.Data) {
            this.jsonId = res.Data.Id;
            this.dynamicTags = res.Data.InventSymbollist;
          }
          console.log(this.dynamicTags);
        });
    },
  },
};
</script>
<style lang="less">
.moreTag {
  margin-right: 10px;
  cursor: pointer;
}
.el-tag {
  margin-top: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>

