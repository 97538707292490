<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
    size="70%"
  >
    <el-form ref="form" :model="entity" label-width="110px" :rules="rules" >
      <div class="posBox">
        <span>固定录入项</span>
      </div>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item label="所属公司">
            <CompanySearchSelect
              v-model="entity.CompanyId"
              @select="CompanySelect"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8"
          ><el-form-item label="编码" prop="cInvCode">
            <el-input
              :maxlength="50"
              show-word-limit
              v-model="entity.cInvCode"
              placeholder="货品编码"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="8"
          ><el-form-item label="品名" prop="cInvName">
            <el-input
              :maxlength="50"
              show-word-limit
              v-model="entity.cInvName"
              placeholder="货品名称"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="包装">
            <el-input
              :maxlength="50"
              show-word-limit
              v-model="entity.cInvStd"
              placeholder="包装规格"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item label="重量(KG)">
            <el-input-number
              v-model="entity.Weight"
              :precision="2"
              :step="0.1"
              controls-position="right"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
					<!-- 1.el-input-number的使用
					2.禁用按钮 disabled
					3.设置 step
					4.设置严格 step step-strictly
					5.精度设置 precision
					6.按钮大小 size
					7.按钮位置 controls-position -->
          <el-form-item label="销售价格">
            <el-input-number
              v-model="entity.iInvRCost"
              :precision="2"
              :step="0.1"
              controls-position="right"
              :min="0"
            ></el-input-number> </el-form-item
        ></el-col>
				<el-col :span="8">
				  <el-form-item label="账期一价格" >
				    <el-input-number
				      v-model="entity.iInvRCostOne"
				      :precision="2"
				      :step="0.1"
				      controls-position="right"
				      :min="0"
				    ></el-input-number> </el-form-item
				></el-col>
				<el-col :span="8">
				  <el-form-item label="账期二价格"  >
				    <el-input-number
				      v-model="entity.iInvRCostTwo"
				      :precision="2"
				      :step="0.1"
				      controls-position="right"
				      :min="0"
				    ></el-input-number> </el-form-item
				></el-col>
				<el-col :span="8">
				  <el-form-item label="账期三价格">
				    <el-input-number
				      v-model="entity.iInvRCostThree"
				      :precision="2"
				      :step="0.1"
				      controls-position="right"
				      :min="0"
				    ></el-input-number> </el-form-item
				></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="条码" prop="IsBarcode">
            <el-switch
              @change="switchChange"
              v-model="entity.IsBarcode"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="类型">
            <InventTypeSelect
              :CommpayId="entity.CompanyId"
              v-model="entity.TypeId"
              @select="TypeSelect"
            />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="单位">
            <el-input
              :maxlength="50"
              show-word-limit
              v-model="entity.GroupName"
              placeholder="请输入单位"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8"
          ><el-form-item label="标准码(69)" v-if="entity.IsBarcode">
            <el-input
              :maxlength="50"
              show-word-limit
              v-model="entity.GuoBiao"
              placeholder="标准码"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="8">
          <el-form-item
            label="序号码(SN)"
            v-if="entity.IsBarcode"
            prop="IsSequence"
          >
            <el-switch v-model="entity.IsSequence"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="tagList.length > 0">
        <div class="posBox">
          <span>增补扩展项</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in tagList" :key="index">
            <el-form-item :label="item.title">
              <el-input v-model="entity[item.dataIndex]"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div v-if="entity.IsBarcode">
      <el-tabs v-model="activeName">
        <!-- <el-tab-pane label="二维码内容" name="1">
          <el-table
            :data="tableCodeData"
            style="width: 100%"
            :max-height="600"
            @selection-change="handleSelectionChange"
            ref="multipleTable"
          >
            <el-table-column type="selection" width="100" align="center">
            </el-table-column>
            <el-table-column label="码段" prop="code" align="center">
            </el-table-column>
            <el-table-column label="顺序">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.sort"
                  clearable
                  :disabled="
                    selectRows.findIndex(
                      (item) => item.code == scope.row.code
                    ) == -1
                  "
                >
                  <el-option
                    v-for="item in sortList"
                    :key="item"
                    :label="item"
                    :value="item"
                    :disabled="
                      tableCodeData.findIndex((im) => im.sort == item) !== -1
                    "
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane> -->
        <el-tab-pane label="客户货品编码对照表" name="2">
          <el-table
            :data="tableData"
            style="width: 100%"
            :max-height="600"
            :row-key="Functionrow"
          >
            <el-table-column label="客户">
              <template slot-scope="scope">
                <CustomerSearchSelect
                  v-model="scope.row.cCusName"
                  @select="CustomerSelect($event, scope.$index)"
                  :disabled="!entity.CompanyId"
                  :CompanyId="entity.CompanyId"
                />
              </template>
            </el-table-column>
            <el-table-column label="客户货品编码">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.CusInvCode"
                  :maxlength="50"
                  show-word-limit
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="70" align="center">
              <template slot-scope="scope">
                <el-popconfirm
                  title="确定要删除本条厂家信息吗?"
                  @confirm="handleDelete(scope)"
                >
                  <el-button slot="reference" type="text">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button
        v-if="entity.IsBarcode"
        v-show="activeName == 2"
        icon="el-icon-plus"
        type="primary"
        @click="handleAdd()"
        >新增</el-button
      >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
    <GoodsAddSymbol
      ref="GoodsAddSymbol"
      @close="getTagList(entity.CompanyId)"
    />
  </el-drawer>
</template>

<script>
import CustomerSearchSelect from "@/components/CustomerSearchSelect";
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
import InventTypeSelect from "@/components/InventTypeSearchSelect.vue";
import GoodsAddSymbol from "@/components/GoodsAddSymbol.vue";
export default {
  components: {
    CompanySearchSelect,
    CustomerSearchSelect,
    GoodsAddSymbol,
    InventTypeSelect,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      entity: {
        IsSequence: false,
        IsBarcode: false,
        GuoBiao: "",
        cInvCode: "",
        cInvName: "",
        cInvStd: "",
        Weight: "",
				// 销售价格
        iInvRCost: "",
				// 账期一价格
				iInvRCostOne:"",
				// 账期二价格
				iInvRCostTwo:"",
				// 账期三价格
				iInvRCostThree:""
      },
      rules: {
        cInvCode: [
          { required: true, message: "请输入货品编号", trigger: "blur" },
        ],
        cInvName: [
          { required: true, message: "请输入货品名称", trigger: "blur" },
        ],
        cInvStd: [
          { required: true, message: "请输入规格型号", trigger: "blur" },
        ],
        iInvRCost: [
          { required: true, message: "请输入销售价格", trigger: "blur" },
        ],
				iInvRCostOne: [
				  { required: true, message: "请输入账期一价格", trigger: "blur" },
				],
				iInvRCostTwo: [
				  { required: true, message: "请输入账期二价格", trigger: "blur" },
				],
				iInvRCostThree: [
				  { required: true, message: "请输入账期三价格", trigger: "blur" },
				],
        Weight: [{ required: true, message: "请输入重量", trigger: "blur" }],
      },
      btnLoading: false,
      title: "",
      loading: false,
      timeout: null,
      tableData: [],
      tableCodeData: [
        { code: "商品码", sort: "", index: 1 },
        { code: "厂商码", sort: "", index: 2 },
        { code: "批次码", sort: "", index: 3 },
        { code: "序号码", sort: "", index: 4 },
        { code: "国码", sort: "", index: 5 },
        { code: "客户商品码", sort: "", index: 6 },
      ],
      sortList: [1, 2, 3, 4, 5, 6],
      selectRows: [],
      activeName: "2",
      tagList: [],
    };
  },
  methods: {
    Functionrow(row) {
      // console.log(row)
      return row.timeId;
    },
    saveCustomerGoods() {
      this.$http
        .post("/BO/InventCustomers/AddDataList?Code=" + this.entity.cInvCode, {
          Data: this.tableData,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.parentObj.getDataList();
            this.drawer = false;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 客户选择赋值
    CustomerSelect(row, index) {
      let idx = this.tableData.findIndex((item) => item.cCusCode == row.Id);
      if (idx !== -1) {
        this.$set(this.tableData, index, {
          timeId: new Date().getTime() + "",
          CusInvCode: this.tableData[index].CusInvCode,
          cCusName: "",
        });
        this.$message.warning("当前选择客户已存在");
        return;
      }
      this.tableData[index].cCusName = row.cCusName;
      this.tableData[index].cCusCode = row.Id;
    },
    // 启用
    switchChange(e) {
      console.log(e);
      if (!e) {
        this.selectRows = [];
        this.entity.GuoBiao = "";
        this.entity.IsSequence = false;
      } else {
        this.entity.IsSequence = false;
      }
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },
    handleAdd() {
      this.tableData.unshift({
        timeId: new Date().getTime() + "",
        CusInvCode: "",
        cCusName: "",
      });
    },
    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    // 公司选择
    CompanySelect(row) {
      this.entity.CompanyName = row.Title;
      this.entity.CompanyId = row.Id;
    },
    TypeSelect(row) {
      this.entity.Name = row.Name;
      this.entity.TypeId = row.Id;
    },
    init() {
      this.entity = {
        IsSequence: false,
        IsBarcode: false,
        GuoBiao: "",
        cInvCode: "",
        cInvName: "",
        cInvStd: "",
        Weight: "",
        iInvRCost: "",
				iInvRCostOne:"",
				iInvRCostTwo:"",
				iInvRCostThree:""
      };
      this.tagList = [];
      this.tableData = [];
      this.tableCodeData = [
        { code: "商品码", sort: "", index: 1 },
        { code: "厂商码", sort: "", index: 2 },
        { code: "批次码", sort: "", index: 3 },
        { code: "序号码", sort: "", index: 4 },
        { code: "国码", sort: "", index: 5 },
        { code: "客户商品码", sort: "", index: 6 },
      ];
      this.selectRows = [];
      this.drawer = true;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
      if (this.$refs["form"]) {
        this.$refs["form"].clearValidate();
      }
    },
    openForm(Id, CompanyId) {
      this.init();
      if (CompanyId) {
        this.entity.CompanyId = CompanyId;
        this.getTagList(this.entity.CompanyId);
      }
      this.title = "新增货品信息";
      if (Id) {
        this.title = "编辑货品信息";
        this.getTheData(Id);
      }
    },
    handleClose(done) {
      done();
    },
    handleSelect(item) {
      console.log(item);
    },
    getTheData(id) {
      this.$http.post("/BO/Inventory/GetTheData", { id }).then((res) => {
        if (res.Success) {
          this.entity = res.Data;
          console.log(this.entity);
          this.entity.IsSequence
            ? this.$set(this.entity, "IsSequence", true)
            : this.$set(this.entity, "IsSequence", false);
          // 二维码排序
          if (this.entity.Sort) {
            this.entity.Sort.split(",").forEach((item, index) => {
              if (item != 0) {
                this.$set(this.tableCodeData[item - 1], "sort", index + 1);
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(
                    this.tableCodeData[item - 1]
                  );
                });
              }
            });
          }
          this.getTagList(this.entity.CompanyId);
          // 客户编码表
          this.$http
            .post("/BO/InventCustomers/GetDataList", {
              Search: { Condition: "cInvCode", Keyword: this.entity.cInvCode },
            })
            .then((res) => {
              if (res.Success) {
                this.tableData = res.Data;
              }
            });
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 获取增补项
    getTagList(CompanyId) {
      this.$http
        .post(
          "/Base_Manage/Base_Attribute/GetInventSymbolList?CompanyId=" +
            CompanyId
        )
        .then((res) => {
          if (res.Success) {
            this.tagList = res.Data.InventSymbollist;
          }
        });
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        // // 启用条码验证
        // if (this.entity.IsBarcode && this.selectRows.length == 0)
        //   return this.$message.error('请至少勾选一项二维码内容')
        // // 排序验证
        // if (!this.selectRows.every((item) => item.sort > 0))
        //   return this.$message.error('请选择排序')
        // let code = [0, 0, 0, 0, 0, 0]
        // this.tableCodeData.forEach((item) => {
        //   console.log(item.sort, 'index', item.index)
        //   if (item.sort) {
        //     code[item.sort - 1] = item.index
        //   }
        // })
        // code = code.join(',')
        // this.entity.Barcode = code
        // this.entity.Sort = code
        this.tableData.forEach((item) => {
          item.CompanyId = this.entity.CompanyId;
          item.cInvCode = this.entity.cInvCode;
        });

        this.btnLoading = true;
        this.$http.post("/BO/Inventory/SaveData", this.entity).then((res) => {
          this.btnLoading = false;
          if (res.Success) {
            this.saveCustomerGoods();
          } else {
            this.$message.error(res.Msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
}

.drawerClass {
  padding: 10px;
  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
