<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
    size="70%"
  >
    <div class="searchNav" style="padding: 0 20px">
      <!-- <h2>联系人</h2> -->
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="dialogVisible = true"
        >新增</el-button
      >
      <div class="searchParams">
        <!-- <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div> -->
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入分类名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div style="padding: 20px">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <el-table-column prop="Name" label="名称"> </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
    </div>
    <el-dialog
      title="编辑商品分类"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      append-to-body
    >
      <el-input
        v-model="typeObj.Name"
        placeholder="请输入商品分类名称"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :loading="dialogLoading"
          type="primary"
          @click="dialogConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-drawer>
</template>

<script>
export default {
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      btnLoading: false,
      title: "",
      loading: false,
      timeout: null,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: { CommpayId: "", Condition: "Name" },
      sortList: [1, 2, 3, 4, 5, 6],
      selectRows: [],
      activeName: "2",
      tagList: [],
      dialogVisible: false,
      typeObj: {},
      dialogLoading: false,
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    dialogConfirm() {
      if (!this.typeObj.Name) return this.$message.error("请输入商品分类名称");
      if (!this.typeObj.CompanyId)
        this.typeObj.CompanyId = this.queryParam.CommpayId;
      this.dialogLoading = true;
      this.$http.post("/BO/InventType/SaveData", this.typeObj).then((res) => {
        this.dialogLoading = false;
        if (res.Success) {
          this.dialogVisible = false;
          this.$message.success("操作成功");
          this.getDataList();
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 列表项编辑
    handleView(row) {
      this.typeObj = row;
      this.dialogVisible = true;
    },
    // 分类新增
    handleAdd() {},
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    saveCustomerGoods() {
      this.$http
        .post("/BO/InventCustomers/AddDataList?Code=" + this.entity.cInvCode, {
          Data: this.tableData,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.parentObj.getDataList();
            this.drawer = false;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },

    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    // 公司选择
    CompanySelect(row) {
      this.entity.CompanyName = row.Title;
      this.entity.CompanyId = row.Id;
    },
    init() {
      this.drawer = true;
    },
    openForm(Id) {
      console.log("传递过来的两个id", Id);
      this.init();
      if (Id) {
        this.queryParam.CommpayId = Id;
        this.getDataList();
      }
      this.title = "商品分类";
    },
    // 获取商品分类列表数据
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/InventType/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },

    handleClose(done) {
      done();
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
}

.drawerClass {
  padding: 10px;

  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
