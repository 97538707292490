<template>
  <div>
    <el-select
      @change="handleCompanySelect"
      v-model="curValue"
      placeholder="请选择类型"
      style="width: 24%"
      :disabled="disabled"
    >
      <el-option
        v-for="item in List"
        :key="item.Id"
        :label="item.Name"
        :value="item.Id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: { require: false, default: "", type: String },
    disabled: { require: false, default: false, type: Boolean },
    CommpayId: { require: true, type: String },
  },
  data() {
    return {
      curValue: "",
      List: [],
    };
  },
  watch: {
    value: {
      handler(n, o) {
        this.curValue = n;
        this.$emit("input", this.curValue);
      },
      deep: true,
      immediate: true,
    },
    CommpayId: {
      handler(n, o) {
        if (n) this.getDataList(n);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    getDataList(id) {
      this.$http
        .post("/BO/InventType/GetDataList", {
          PageIndex: 1,
          PageRows: 9999,
          SortField: "Id",
          SortType: "desc",
          Search: {
            CommpayId: id,
          },
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.List = resJson.Data;
          }
        });
    },
    handleCompanySelect(item) {
      let idx = this.List.findIndex((im) => im.Id == item);
      this.$emit("select", this.List[idx]);

      this.$emit("input", this.curValue);
    },
  },
};
</script>

<style></style>
